.Admin-page {
  text-align: center;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.Admin-page-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;
}

.Config-menu-content {
  background-color: black;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: calc(10px + 1vmin);
  color: white;
}

.Templates-menu-content {
  background-color: black;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: calc(10px + 1vmin);
  color: white;
}

.Blind-bag-menu-content {
  background-color: black;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: calc(10px + 1vmin);
  color: white;
}

.Raffle-menu-content {
  background-color: black;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: calc(10px + 1vmin);
  color: white;
}

.Rng-menu-content {
  background-color: black;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: calc(10px + 1vmin);
  color: white;
}

.Activities-menu-content {
  background-color: black;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: calc(10px + 1vmin);
  color: white;
}

.Pad-top-and-bottom-10px {
  padding-top: 10px;
  padding-bottom: 10px;
}

.Black-background {
  background-color: black;
}

.navbar,
.navbar-default {
    background-image: linear-gradient(to bottom,#000000 0,#000000 100%) !important; /* override background image gradient w/ flat color */
}

.navbar.navbar-nav.btn-group .dropdown-menu li a:hover {
  color: whitesmoke !important;
  background: #bf5279 !important;
}

.html,
body{overflow-x: hidden;}